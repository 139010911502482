import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { getSrc } from 'gatsby-plugin-image';

import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';
import SliceZone from '../components/SliceZone';

import { breakpoint } from '../theme';
import { SiteInformation, PageContent, PackageItems } from '../utils/fragments';

const Container = styled.div`
  margin: ${rem(0)} auto 0;
  padding-top: ${rem(50)};
  padding-bottom: ${rem(50)};

  ${breakpoint('mediumlarge')} {
    padding-top: ${rem(64)};
  }

  ${breakpoint('large')} {
    padding-top: ${rem(70)};
  }

  ${(props) => props.centered && `max-width: ${props.theme.grid.maxWidthText};`}
`;

const PostContent = styled.div`
  font-size: ${rem(16.5)};

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }

  & .wp-block-image {
    margin-top: ${rem(32)};
    margin-bottom: ${rem(22)};
  }

  & figcaption {
    color: ${(props) => props.theme.palette.colors.fadedText};
    font-size: ${rem(14)};
    font-weight: ${(props) => props.theme.typography.weights.primary.normal};
    font-family: ${(props) => props.theme.typography.fonts.primary};
  }
`;

function Page({ data }) {
  const { page, allPackages } = data;

  useLangRedirect(page);

  if (!page) {
    return null;
  }

  const {
    featuredImage,
    elementorDataRendered,
    elementorEditMode,
    lang,
    seo,
    sections: pageSections,
    pageOptions: { heroSize },
    hero,
    meta: { subTitle },
  } = page;
  const isCustomLayout = elementorEditMode === 'builder';
  const featuredMedia = featuredImage?.node;
  const itemImageSrc = getSrc(featuredMedia?.localFile);
  const withHero = true;
  const backgroundVideo = hero?.backgroundvideo?.localFile?.url;
  const videoPoster = hero?.backgroundvideoposter;

  return (
    <Layout headerProps={{ toggleLogoOnStuck: heroSize !== 'default' }}>
      <SEO
        title={seo.title}
        description={seo.metaDesc || seo.opengraphDescription}
        image={itemImageSrc}
        page={page}
        lang={lang}
      />

      {withHero && (
        <Hero
          heading={page.title}
          image={videoPoster?.localFile || featuredMedia?.localFile}
          pageTitle
          // actions="Actions"
          videoProps={{ src: backgroundVideo }}
          tint
          big={heroSize === 'big'}
          logo={heroSize === 'big'}
        >
          {subTitle && <p>{subTitle}</p>}
        </Hero>
      )}

      {isCustomLayout ? (
        <div
          dangerouslySetInnerHTML={{ __html: elementorDataRendered }} // eslint-disable-line react/no-danger
        />
      ) : (
        <>
          {page.content && (
            <Container centered>
              <Row>
                <Col>
                  <PostContent
                    dangerouslySetInnerHTML={{ __html: page.content }} // eslint-disable-line react/no-danger
                  />
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}

      {pageSections.sections && (
        <SliceZone
          slices={pageSections.sections}
          additionals={{
            allPackages: allPackages?.edges.map(({ node }) => node),
          }}
        />
      )}
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

Page.fragments = [PageContent, SiteInformation, PackageItems];

export const query = graphql`
  query ($id: String!, $lang: String!) {
    page: wpPage(id: { eq: $id }) {
      elementorDataRendered
      elementorEditMode
      ...PageContent
    }
    site {
      ...SiteInformation
    }
    allPackages: allWpPackage(
      filter: { lang: { eq: $lang } }
      sort: { fields: menuOrder, order: ASC }
      limit: 6
    ) {
      ...PackageItems
    }
  }
`;

export default Page;
